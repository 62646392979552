.multipanel {

  .table_container {
    overflow-y: scroll;
    flex: 1 1 auto;
    height: calc(100vh - 490px)
  }

  .table_border {
    border-collapse: collapse;
    mso-cellspacing: 0;
  }

  .table, td {
    border: 1px solid black;
    padding: 3px;
  }

  tr:nth-child(even) {
    background-color: lightblue;
  }

  tr:nth-child(odd) {
    background-color: white;
  }

  .column_header {
    background-color: #374c72;
    color: white;
  }
}

.transform-item-selected {
  color: white !important;
  background-color: blue !important;
}

.transform-icon {
  text-align: center !important;
  width: 100% !important;
  font-size: 100px !important;
  margin-top: 20px;
}