@import './webviewer-multipanel/styles.scss';

.ColorPalette {
  display: flex;  // fallback for ie11, grid not available
  flex-wrap: wrap; // fallback for ie11, grid not available
  @include ie11 {
    width: 196px; // hack for ie11. Have to define width for flex basis to work.
  }
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  max-width: 200px;

  &.padding {
    padding-top: 4px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
  }

  @include mobile {
    max-width: 450px;
    width: auto;
    @include ie11 {
      width: 308px; // hack for ie11. Have to define width for flex basis to work.
    }
  }

  .cell-container {
    @include button-reset;
    flex: 1 0 14%; // fallback for ie11, grid not available

    cursor: pointer;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile {
      width: 44px;
      height: 44px;
    }

    .cell-outer {
      &.active {
        border: 1px solid black;
        width: 26px;
        height: 26px;
        border-radius: 10000000px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include mobile  {
          width: 36px;
          height: 36px;
        }
      }

      .cell {
        width: 18px;
        height: 18px;
        border-radius: 10000000px;

        .transparent {
          border: 2px solid var(--faded-text);
          border-radius: 10000000px;
        }

        &.border{
          border: 1px solid gray;
        }

        @include mobile  {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
.disabled-div {
  //display: none;
  opacity: 0.4;
  pointer-events: none;
}

.button-container {
  margin-top: 20px;
  text-align: left;
}

button:disabled {
   opacity: 0.6;
   background-color: #ccc;
   cursor: not-allowed;
 }

.rounded-button {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
}

.cancel-button {
  background-color: #217DA2;
  margin-right: 10px;
}

.ok-button {
  background-color: #217DA2;
}
